import { mdiChevronUp } from '@/client/icons';
import Icon from '@mdi/react';
import clsx from 'clsx';
import type { HTMLAttributes, RefAttributes } from 'react';
import { forwardRef, Suspense, useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useRecoilValue } from 'recoil';
import { appCustomLayoutState } from '../../atoms/appCustomLayoutState';
import type { AppCustomHeaderNavBarContentProps } from './AppCustomHeaderNavBarContent';
import AppCustomHeaderNavBarContent from './AppCustomHeaderNavBarContent';
import AppCustomHeaderNavBarError from './AppCustomHeaderNavBarError';
import AppCustomHeaderNavBarLinks from './AppCustomHeaderNavBarLinks';
import AppCustomHeaderNavBarSkeleton from './AppCustomHeaderNavBarSkeleton';

export type AppCustomHeaderNavBarProps = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> &
  Omit<AppCustomHeaderNavBarContentProps, 'open'> & {
    mainNavAfter?: React.ReactNode;
    navOffsetTop: number | undefined;
    open?: true | undefined;
  };

const AppCustomHeaderNavBar = forwardRef<HTMLDivElement, AppCustomHeaderNavBarProps>(
  ({ className, mainNavAfter, navOffsetTop, open, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(!!open);
    const { isMobileMenuOpen } = useRecoilValue(appCustomLayoutState);

    const handleMenuOpening = useCallback(() => {
      setIsOpen((prev) => !prev);
    }, [setIsOpen]);

    useEffect(() => {
      if (isMobileMenuOpen) {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      } else {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
      }
      return () => {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
      };
    }, [isMobileMenuOpen]);

    return (
      <div
        {...props}
        ref={ref}
        className={clsx(
          'relative top-0 z-[49] mx-[-0.5rem] lg:top-[-65px] lg:mx-0 lg:block',
          className,
          isMobileMenuOpen ? null : 'hidden',
        )}
      >
        <div
          className='fixed z-10 min-h-[56px] w-full overflow-y-auto bg-primary text-white lg:static lg:!max-h-none lg:overflow-y-visible'
          style={{
            maxHeight: `calc(100vh - (${navOffsetTop}px + env(safe-area-inset-bottom)))`,
            top: `${navOffsetTop}px`,
          }}
        >
          <div className='flex min-h-[56px] flex-col lg:flex-row'>
            <div className='relative flex w-full flex-col  items-center lg:w-[280px] lg:bg-primary-600'>
              <button className='w-full' onClick={handleMenuOpening}>
                <p className='relative flex h-[65px] w-full items-center justify-center text-center font-bold uppercase lg:justify-start lg:pl-4'>
                  kategorie
                  <Icon
                    className={clsx('absolute right-2 text-white transition-all duration-300', {
                      'rotate-180 transform': !isOpen,
                    })}
                    path={mdiChevronUp}
                    size='32px'
                  />
                </p>
              </button>
              <div className='side-panel top-[56px] left-0 w-full'>
                <ErrorBoundary fallback={<AppCustomHeaderNavBarError />}>
                  <Suspense fallback={<AppCustomHeaderNavBarSkeleton />}>
                    <AppCustomHeaderNavBarContent open={isOpen} />
                    <AppCustomHeaderNavBarLinks />
                  </Suspense>
                </ErrorBoundary>
                {mainNavAfter}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

AppCustomHeaderNavBar.displayName = 'AppCustomHeaderNavBar';

export default AppCustomHeaderNavBar;
