import AppbarLoginRegistrationAction from '@/client/compositions/AppbarLoginRegistrationAction';
import ChangePricesType from '@/client/compositions/ChangePricesType';
import GlobalSearchIconButton from '@/client/compositions/GlobalSearchIconButton';
import ShoppingCartIndicator from '@/client/compositions/ShoppingCartIndicator';
import { useRecoilStateEventHandler } from '@/client/hooks';
import { mdiCloseThick, mdiMenu } from '@/client/icons';
import Icon from '@mdi/react';
import type { FC, HTMLAttributes } from 'react';
import { appCustomLayoutState } from '../../atoms/appCustomLayoutState';
import AppCustomHeaderLogo from '../AppCustomHeaderLogo';

export type AppCustomHeaderTopToolbarContentProps = HTMLAttributes<HTMLDivElement>;

const AppCustomHeaderTopToolbarContent: FC<AppCustomHeaderTopToolbarContentProps> = () => (
  <div className='flex w-full flex-wrap items-center text-toptoolbar-contrast lg:flex-nowrap'>
    <AppCustomHeaderLogo />
    <div className='order-2 mx-auto mb-4 flex w-full justify-center lg:order-[0] lg:mb-0'>
      <div className='w-full max-w-[570px]'>
        <GlobalSearchIconButton />
      </div>
    </div>
    <ChangePricesType className='hidden lg:flex' />
    <AppbarLoginRegistrationAction className='ml-auto px-2 lg:ml-2' />
    <ShoppingCartIndicator />
    <AppCustomMobileMenuOpenButton />
  </div>
);

AppCustomHeaderTopToolbarContent.displayName = 'AppCustomHeaderTopToolbarContent';

export default AppCustomHeaderTopToolbarContent;

const AppCustomMobileMenuOpenButton: FC = () => {
  const [layoutState, handleSetMobileOpen] = useRecoilStateEventHandler(
    appCustomLayoutState,
    (v: boolean, { isMobileMenuOpen, ...o }) => ({ isMobileMenuOpen: v, ...o }),
  );
  const { isMobileMenuOpen } = layoutState;
  return (
    <div>
      <button
        className='relative my-2 flex max-h-[52px] flex-col justify-center rounded-md bg-primary-200 py-2 px-1 lg:hidden'
        onClick={handleSetMobileOpen(!isMobileMenuOpen)}
      >
        {!isMobileMenuOpen ? (
          <Icon className='mb-2' path={mdiMenu} size='33px' />
        ) : (
          <Icon className='mb-2' path={mdiCloseThick} size='33px' />
        )}

        <span className='absolute bottom-[5px] left-[7px] flex text-[10px] text-black'>Menu</span>
      </button>
    </div>
  );
};
