import clsx from 'clsx';
import type { HTMLAttributes, RefAttributes } from 'react';
import { forwardRef, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { AppCustomHeaderTopToolbarContentProps } from './AppCustomHeaderTopToolbarContent';
import AppCustomHeaderTopToolbarContent from './AppCustomHeaderTopToolbarContent';
import AppCustomHeaderTopToolbarError from './AppCustomHeaderTopToolbarError';
import AppCustomHeaderTopToolbarSkeleton from './AppCustomHeaderTopToolbarSkeleton';

export type AppCustomHeaderTopToolbarProps = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> &
  AppCustomHeaderTopToolbarContentProps;

const AppCustomHeaderTopToolbar = forwardRef<HTMLDivElement, AppCustomHeaderTopToolbarProps>(
  ({ className, ...props }, ref) => (
    <header
      {...props}
      ref={ref}
      className={clsx('main-content flex h-[105px] items-center bg-toptoolbar drop-shadow-md print:hidden', className)}
    >
      <div className='container'>
        <ErrorBoundary fallback={<AppCustomHeaderTopToolbarError />}>
          <Suspense fallback={<AppCustomHeaderTopToolbarSkeleton />}>
            <AppCustomHeaderTopToolbarContent />
          </Suspense>
        </ErrorBoundary>
      </div>
    </header>
  ),
);

AppCustomHeaderTopToolbar.displayName = 'AppCustomHeaderTopToolbar';

export default AppCustomHeaderTopToolbar;
