import { authUserState } from '@/client/atoms';
import Menu from '@/client/components/Menu';
import ProfileNavigationItems from '@/client/compositions/Profile/ProfileNavigationItems';
import { useWindowSizeIsMobile } from '@/client/hooks';
import { useHydrated } from '@/client/hooks/use-hydrated';
import { addUserDataLayer } from '@/client/lib/googleAnalytics';
import trpc from '@/client/lib/trpc';
import clsx from 'clsx';
import type { FC } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue } from 'recoil';
import invariant from 'tiny-invariant';
import LogoutButton from '../LogoutButton';

export type LoggedInProps = {
  className?: string;
};

const LoggedIn: FC<LoggedInProps> = ({ className }) => {
  const authUser = useRecoilValue(authUserState);
  const isMobile = useWindowSizeIsMobile();
  const isHydrated = useHydrated();
  const trpcCtx = trpc.useContext();

  useEffect(() => {
    const ac = new AbortController();
    async function callit() {
      const ordersCount = await trpcCtx.profile.orders.count.fetch(undefined, { signal: ac.signal });
      addUserDataLayer(authUser, ordersCount);
    }
    if (isHydrated && authUser != null) {
      callit();
    }
    return () => {
      ac.abort();
    };
  }, [trpcCtx, isHydrated, authUser]);

  invariant(
    authUser,
    'LoggedIn component is rendered for unauthenticated user. There is some problem with conditions.',
  );

  return (
    <div className={clsx('flex justify-between', className)}>
      <Menu
        contentStyle={!isMobile ? { margin: '16px 0 0 11px' } : {}}
        position='bottom right'
        trigger={
          <span className='mt-3 inline-flex flex-col items-center justify-center md:min-w-[4rem] lg:mt-0 lg:min-w-[5rem]'>
            <img alt='user' className='mx-auto mb-2' height='28' src='/images/user.webp' width='28' />
            <span className='hidden font-bold hover:underline lg:inline-block'>
              <FormattedMessage defaultMessage='Můj účet' description='my account' id='4u90US' />
            </span>
          </span>
        }
      >
        <Menu.Items className='right-[-1rem] mx-2 text-[0.9375rem]'>
          <Menu.Item className='text-[#333] underline hover:bg-[#f5f5f5] hover:text-[#262626] hover:no-underline'>
            <ProfileNavigationItems.Account />
          </Menu.Item>
          <Menu.Item className='text-[#333] underline hover:bg-[#f5f5f5] hover:text-[#262626] hover:no-underline'>
            <ProfileNavigationItems.LoyaltyProgramme dropdown />
          </Menu.Item>
          <Menu.Item className='text-[#333] underline hover:bg-[#f5f5f5] hover:text-[#262626] hover:no-underline'>
            <ProfileNavigationItems.Orders dropdown />
          </Menu.Item>
          <Menu.Item className='text-[#333] underline hover:bg-[#f5f5f5] hover:text-[#262626] hover:no-underline'>
            <ProfileNavigationItems.Invoices dropdown />
          </Menu.Item>
          <Menu.Item className='text-[#333] underline hover:bg-[#f5f5f5] hover:text-[#262626] hover:no-underline'>
            <LogoutButton className='underline hover:no-underline' />
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
};

LoggedIn.displayName = 'LoggedIn';

export default LoggedIn;
