import { pricesTypeState } from '@/client/atoms';
import FormattedPrice from '@/client/components/FormattedPrice';
import { useCartTotals, useRouteUrl } from '@/client/hooks';
import { mdiCart, mdiClose } from '@/client/icons';
import type { TrpcRouterOutputs } from '@/client/lib/trpc';
import { Popover, Transition } from '@headlessui/react';
import Icon from '@mdi/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import CartAsideDelivery from '../CartAsideDelivery';
import CartAsidePreview from '../CartAsidePreview';
import CartAsideTotals from '../CartAsideTotals';

export default function ShoppingCartIndicator({ className }: { className?: string }) {
  const intl = useIntl();
  const routeUrl = useRouteUrl();
  const cartTotals = useCartTotals();
  const match = useRouteMatch();

  const cartUrl = routeUrl('cart', {});
  const inCart = match.url.startsWith(cartUrl);

  return (
    <>
      <RouterLink
        className={clsx('relative mt-[8px] inline-flex flex-col p-2 md:min-w-[126px]', className, 'md:hidden')}
        to={routeUrl('cart', {}, true)}
      >
        <ShoppingCartIndicatorIconWithCount cartTotals={cartTotals.data} />
      </RouterLink>
      <Popover>
        {({ close }) => (
          <>
            <Popover.Button
              className={clsx(
                'relative mt-[8px] inline-flex flex-col p-2 md:min-w-[126px]',
                className,
                'hidden md:inline-flex',
              )}
            >
              <ShoppingCartIndicatorIconWithCount cartTotals={cartTotals.data} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute right-0 z-40 mt-1 w-screen transform px-4 sm:max-w-screen-sm sm:px-0'>
                <div className='flex max-h-[60vh] flex-col overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className='flex bg-white px-4 py-2 text-text'>
                    <span className='flex-1 text-2xl font-semibold'>
                      {intl.formatMessage({
                        defaultMessage: 'Váš košík',
                        description: 'Aside shopping cart preview title.',
                        id: 'Oe8w1j',
                      })}
                    </span>
                    <button
                      onClick={() => {
                        close();
                      }}
                    >
                      <Icon className='text-text' path={mdiClose} size='1.5rem' />
                    </button>
                  </div>
                  <CartAsidePreview cartTotals={cartTotals.data} className='flex-1 overflow-y-auto bg-white p-4' />
                  <CartAsideTotals cartTotals={cartTotals.data} className='w-full bg-[#d9edf7] px-4 py-2' />
                  <div className='flex flex-col bg-white p-4 text-text sm:flex-row'>
                    <CartAsideDelivery className='flex-1' />
                    <div className='mt-4 flex items-center sm:mt-0'>
                      {inCart === false && (
                        <RouterLink
                          className='inline-flex max-h-12 w-full flex-row items-center justify-center gap-1 rounded bg-secondary px-4 py-3 font-semibold text-white transition-colors duration-100 hover:bg-secondary-600 active:bg-secondary-600'
                          to={cartUrl}
                        >
                          <FormattedMessage defaultMessage='Přejít do košíku' description='Move to cart' id='+5Xye3' />
                          <Icon className='text-white' path={mdiCart} size='1.5rem' />
                        </RouterLink>
                      )}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}

function ShoppingCartIndicatorCount({ cartTotals }: { cartTotals: TrpcRouterOutputs['cart']['totals'] }) {
  if (cartTotals.subjects.count === 0) {
    return null;
  }

  return (
    <span className='absolute bottom-0 left-[4px] flex h-8 w-8 items-center justify-center rounded-full border-[4px] border-white bg-secondary text-[8px] text-white md:top-0 md:left-5'>
      {cartTotals.subjects.count}
    </span>
  );
}

function ShoppingCartIndicatorContent({ cartTotals }: { cartTotals: TrpcRouterOutputs['cart']['totals'] }) {
  const pricesType = useRecoilValue(pricesTypeState);
  const value = pricesType === 'B2C' ? cartTotals.total.total.amountWithTax : cartTotals.total.total.amount;

  return <FormattedPrice currency={cartTotals.total.total.currency} value={value} />;
}

function ShoppingCartIndicatorIconWithCount({ cartTotals }: { cartTotals: TrpcRouterOutputs['cart']['totals'] }) {
  return (
    <>
      <img
        alt='kosik'
        className='mx-auto mb-1 h-[25px] w-auto text-primary md:h-[40px] md:w-auto'
        height='40'
        src='/images/kosik.webp'
        width='40'
      />
      <ShoppingCartIndicatorCount cartTotals={cartTotals} />
      <p className='hidden w-full text-center text-[18px] font-bold text-text md:h-[27px] lg:block'>
        <ShoppingCartIndicatorContent cartTotals={cartTotals} />
      </p>
    </>
  );
}
